@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.Hero {
    height: 85vh;
    display: flex;
    align-items: center;
    width: 100vw;
    margin-left: calc(-100vw / 6);
    position: relative;
    background: linear-gradient(0deg, $color__white 100%, rgba($color__gray-1, 0.15) 100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &__innerWrapper {
        padding: 3rem calc(100vw / 9);
        z-index: 0;
    }

    &__content {
        z-index: 10;

        h1 {
            display: flex;
            flex-direction: column;
            font-size: 64px;
            span {
                font-size: 36px;
                font-weight: 400;
            }
        }
    }
    &__image {
        position: absolute;
        bottom: 0;
        right: calc(100vw / 12);
        z-index: -1;
        max-width: 90vw;
    }

    &--small {
        height: 60vh;
        
        .Hero {
            &__innerWrapper {
                position: relative;
                margin: auto;
                text-align: center;
                border: 3px solid $color__yellow;
                background-color: rgba($color__white, 0.5);
                &:before {
                    content:"";
                    position: absolute;
                    top: 7.5%;
                    left: 5%;
                    background-color: rgba($color__white, 0.5);
                    height: 85%;
                    width: 90%;
                    z-index: -1;
                    border: 3px solid $color__white;
                }

                h1 {
                    span {
                        font-family: $ff;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .Hero {
        margin-left: calc(-100vw / 12);
        
        &__innerWrapper {
            padding-left: calc(100vw / 24);
            padding-right: calc(100vw / 24);
        }

        &__image {
            right: 0;
        }

        &__content {
            h1 {
                font-size: 36px;
            }
        }
    }
}
