.Content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin-bottom: 1rem;
    }
    
    .strong {
        font-weight: bold;
    }

    .em {
        font-style: italic;
    }

    .underline {
        text-decoration: underline;
    }

    .strike-through {
        text-decoration: line-through;
    }
}
