$color__red: #D40C52;
$color__green: #36989B;
$color__orange: #ED8070;
$color__peach: #F8D6C0;
$color__yellow: #FDAA40;

$color__primary: $color__red;
$color__secondary: $color__yellow;

$color__white: #fff;
$color__black: #000;
$color__gray-1: #E7DEDD;
$color__gray-2: #ada4a3;
$color__gray-3: rgb(58, 56, 56);