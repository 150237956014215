.Section {
    display: inline-block;
    vertical-align: top;

    &--full {
        width: 100%;
    }
    &--half {
        width: 50%;

        > div {
            margin: 0 calc(100vw / 48);
        }
    }

    > div {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 5rem;
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 700px) {
    .Section {
        > div {
            margin-top: 4rem;
            margin-bottom: 4rem;
        }

        &--half {
            width: 100%;

            > div {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}
