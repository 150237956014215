@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
.Header {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - (100% / 6));
    padding: 1rem calc(100% / 12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.5s;
    z-index: 100;
    background-color: rgba($color__white, 0.5);

    &--half {
        background-color: rgba($color__gray-3, 0.5);

        .Header__title, .Header__link {
            border-color: $color__white;
            color: $color__white;
        }
    }

    &--full {
        background-color: $color__gray-3;

        .Header__title, .Header__link {
            color: $color__white;
            border-color: $color__white;
        }
    }

    &__title {
        font-size: $fs-l;
        transition: 0.5s;
    }

    &__link {
        padding: 0 0.5rem;
        border-right: 1px solid $color__black;

        &:last-of-type {
            padding-right: 0;
            border-right: none;
        }

        &--active {
            @extend .Header__link;
            font-weight: bold;
        }
    }
}

@media screen and (max-width: 700px) {
    .Header {
        width: calc(100vw - calc(100vw / 12));
        padding-right: calc(100vw / 24);
        padding-left: calc(100vw / 24);

        &__menu {
            position: fixed;
            bottom: 0;
            left: 0;
            padding-right: calc(100vw / 24);
            padding-left: calc(100vw / 24);
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            background: $color__gray-2;
            width: calc(100vw - 2 * (100vw / 24));

            a {
                color: $color__white;
            }
        }
    }
}
