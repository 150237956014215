@import "../../styles/colors.scss";

.Footer {
    background-color: $color__gray-3;
    padding: 3rem calc(100% / 6) 5rem;
    margin-top: 5rem;

    &__title, &__paragraph, &__paragraph a {
        color: $color__white;
    }

    &__title {
        text-align: center;
        margin: 1rem 0 2rem;
    }

    &__paragraph {
        margin: 0 0 0.5rem;
        display: flex;
        align-items: center;
        gap: 0.7rem;
    }

    &__container {
        display: flex;
        justify-content: space-between;
    }
}

@media screen and (max-width: 700px) {
    .Footer {
        &__container {
            flex-direction: column;
        }
    }
}