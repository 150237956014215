@import "../../styles/colors.scss";

.Banner {
    margin-left: calc(-100vw / 6);
    width: 100vw;

    &--gray {
        background-color: $color__gray-1;
    }

    &--yellow {
        background-color: $color__yellow;
        color: $color__gray-3;
    }

    &--red {
        background-color: $color__red;
        color: $color__white;
    }

    &--green {
        background-color: $color__green;
        color: $color__white;
    }
    
    &__innerWrapper {
        padding: 3rem calc(100vw / 6);
        display: flex;
        align-items: center;
        gap: 1rem;

        * {
            flex: 1;
        }
    }
}

@media screen and (max-width: 700px) {
    .Banner {
        margin-left: calc(-100vw / 12);
        padding: 2rem 0;

        &__innerWrapper {
            flex-direction: column;
            padding: 0 calc(100vw / 12);
        }
    }
}