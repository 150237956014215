@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.Box {
    display: flex;
    border: 1px solid $color__gray-1;
    border-radius: 0.7rem;
    box-shadow: 2px 2px 7px rgba($color__black, 0.09);
    padding: 1rem;
    gap: 1rem;

    &:hover {
        transform: scale(1.05);
    }

    &__circle {
        position: relative;
        display: inline-block;
        background-color: $color__primary;
        width: 3.8rem;
        height: 3.8rem;
        border-radius: 100%;

        &--red {
            background-color: $color__red;
        }
        &--orange {
            background-color: $color__orange;
        }
        &--peach {
            background-color: $color__peach;
        }
        &--yellow {
            background-color: $color__yellow;
        }
        &--green {
            background-color: $color__green;
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        height: 100%;
        width: 100%;

        svg {
            width: 2rem;
            height: 2rem;
        }
    }

    &__content {
        flex: 1 1;
    }
}
