@import '../../styles/colors.scss';

.CallToAction__button {
    display: inline-block;
    background-color: $color__red;
    color: $color__white;
    padding: 1rem 2rem;
    border-radius: 1.5rem;
    margin: 1rem 0;

    &:hover {
        background-color: darken($color__red, 10%);
        color: $color__white;
    }
}
