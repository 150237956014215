@import "./styles/fonts.scss";
@import "./styles/colors.scss";

// reset
body {
    font-size: $fs-m;
}

h1,
h2,
h3,
h4,
p,
a {
    margin: 0;
}

h1,
h2,
h3 {
    font-family: $ff-h;
}

p,
a {
    font-family: $ff;
}

a {
    text-decoration: none;
    color: $color__black;

    &:hover {
        color: $color__primary;
    }
}